<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="como_funciona" class="baloo_paaji_regular como_funciona">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="_font_2_5rem cor_red" data-aos="fade-down"><b>Como funciona</b></h1>
          <h5 class="_font_1_2rem" data-aos="fade-up">Rapidez para seu cliente, eficiência para seu negócio.</h5>
          <br>
          <pro-divisor></pro-divisor>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-list three-line style="background: transparent">
              <template v-for="(item, index) in lista">
                <v-list-item :key="'index'+index">
                  <v-list-item-avatar rounded data-aos="slide-right">
                    <v-img :src="item.src" contain class="img_fun"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <h2 data-aos="slide-left" class="_font_2rem">{{item.titulo}}</h2>
                    <h5 data-aos="slide-left" class="_font_1rem">{{item.descricao}}</h5>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="forma_video" id="forma_video">
              <v-card height="500" width="100%" flat style="background: transparent">
                <v-row justify="center">
                  <video height="500" id="meuVideo" style="cursor: pointer;" loop autoplay>
                    <source :src="Celular" type="video/mp4">
                    Seu navegador não suporta video HTML.
                  </video>
                  <v-overlay :absolute="true" opacity="0.3" :value="overlay" style="z-index: 0;border-radius: 20% 1%;">
                    <v-btn icon name="bt_play">
                      <v-icon size="80" color="white">mdi-play-circle-outline</v-icon>

                    </v-btn>
                  </v-overlay>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <br>
        <div style="font-size: 1.5rem; text-align: center; width: 100%; padding-left: 15%" id="recursos">
          <vue-typed-js loop :strings="strings" :typeSpeed="50" :backSpeed="50" :startDelay="1000">
            <h3 class="_font_2_5rem">Pedidos via WhatsApp para <span class="typing" style="color: red"></span></h3>
          </vue-typed-js>
        </div>
      </v-container>
    </div>
    <br>
  </div>
</template>

<script async>
  /* eslint-disable */
  import falando from '../../assets/img/Uaiped/falando.png'
  import pedido from '../../assets/img/aaa.png'
  import passo_1 from '../../assets/img/icones/passo-1.png'
  import passo_2 from '../../assets/img/icones/passo-2.png'
  import passo_3 from '../../assets/img/icones/passo-3.png'
  import Celular from '../../assets/img/prints/Celular 3.mp4'

  export default {
    name: 'SecaoComoFunciona',
    data: () => ({
      overlay: true,
      Celular,
      falando,
      pedido,
      passo_1,
      strings: ['seu Restaurante', 'sua Pizzaria', 'sua Distribuidora de Gás', 'sua Lanchonete', 'sua Hamburgueria', 'sua Sorveteria',
        'sua Pastelaria', 'sua Distribuidora de Bebidas','sua Confeitaria'],
      lista: [
        {
          titulo: 'Acesso ao Cardápio',
          descricao: 'O cliente acessa seu cardápio por meio de um link que você poderá compartilhar pelo instagram, facebook ou WhatsApp. O cliente não precisará baixar nenhum aplicativo em seu celular.',
          src: passo_1
        },
        {
          titulo: 'Seleção de Produtos',
          descricao: 'Em poucos cliques, o cliente navega pelo cardápio e seleciona os produtos que deseja.',
          src: passo_2
        },
        {
          titulo: 'Pedido Recebido',
          descricao: 'O pedido chega direto no seu WhatsApp de forma organizada.',
          src: passo_3
        },
      ]
    }),
    methods: {
    },
    mounted() {
      var forma_video = document.getElementById('forma_video')
      var myVideo = document.getElementById('meuVideo')
      var _this = this
      if (myVideo != null) {

        forma_video.addEventListener('click', function() {
          if(_this.overlay) {
            console.log('play')
            myVideo.play()
          } else {
            console.log('pause')
            myVideo.pause()
          }
          _this.overlay = !_this.overlay
        })

        forma_video.addEventListener('dblclick', function() {
          if (myVideo.requestFullscreen) {
            myVideo.requestFullscreen()
          } else if (myVideo.mozRequestFullScreen) {
            myVideo.mozRequestFullScreen()
          } else if (myVideo.webkitRequestFullscreen) {
            myVideo.webkitRequestFullscreen()
          }
          myVideo.play()
        })
      }
    }
  }
</script>
<style scoped>
  .v-card--reveal {
    bottom: 0;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
</style>
